import { Transport } from '@frontend/shared/transport';
import { IApplication } from 'APP/interfaces/applications.interfaces';
import { BizApplication } from 'APP/interfaces/business/application';

export const ApplicationActions = {
    async all(): Promise<{ data: { applications: IApplication[] | BizApplication[] } }> {
        return Transport.get('applications');
    },

    async getAvailableApplicationsByType(): Promise<{
        data: { private: boolean; business: boolean; mortgage: boolean };
    }> {
        return Transport.get('applications/active-types');
    },

    async getAllMortgageApplications(): Promise<{ data: { applications: IApplication[] } }> {
        return Transport.get('applications/mortgage');
    },

    /**
     * @param {string} id
     */
    async get(id: string): Promise<IApplication | BizApplication | undefined> {
        const res = await ApplicationActions.all();
        if (!res.data.applications || res.data.applications.length < 1) {
            throw new Error('Could not find an application');
        }

        const application = await res.data.applications.find((a: IApplication | BizApplication) => a.id === id);
        return application;
    },

    inboxViewedWithApplicationEvent(inboxViewedData: {
        application_id: string;
        revision: number;
    }): Promise<{ status: string }> {
        const event_name = 'inbox_viewed';
        return Transport.post('events', { data: { ...inboxViewedData, event_name } });
    },
};
